<template>
  <div class="edit-task">
      <div class="left-box">
        <div class="row-box">
          <div class="col-box in-col-box">
            <p class="label">客户名称</p>
            <p class="val">
              <template v-if="form.status == 2">
                {{form.customerName}}
              </template>
              <el-select v-else v-model="form.customerId" filterable @change="selectCustomer" placeholder="请选择任务客户">
                <el-option
                  v-for="item in clientList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              <span>{{form.customerType == 1 ? '个人' : '企业'}}</span>
            </p>
          </div>
          <div class="col-box">
            <p class="label">任务名称</p>
            <p class="val" v-if="form.status == 2">{{form.name}}</p>
            <el-input v-else v-model="form.name"></el-input>
          </div>
        </div>
        <div class="row-box">
          <div class="col-box in-col-box">
            <p class="label">业务类型</p>
            <p class="val" v-if="form.status == 2">{{form.businessTypeName}}</p>
            <el-select v-else v-model="form.businessType" @change="selectType" filterable placeholder="请选择业务类型">
              <el-option
                v-for="item in typeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <!-- <p class="label">联系方式</p>
            <p class="val">{{form.contact}}</p> -->
          </div>
          <div class="col-box">
            <!-- <p class="label">创建日期</p>
            <p class="val">{{form.createTime2}}</p> -->
            <p class="label">联系方式</p>
            <p class="val">{{form.contact}}</p>
          </div>
        </div>
        <div class="row-box">
          <div class="col-box">
            <p class="label">服务类型</p>
            <p class="val" v-if="form.status == 2">{{form.typeName}}</p>
            <el-select v-else v-model="form.type" @change="selectServeType" :disabled="!form.businessType" filterable placeholder="请选择业务类型">
              <el-option
                v-for="item in isTypeInfo.childs"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <p class="btn-p" style="cursor:default;color:#666;margin-left: 10px;" v-if="`${isServeTypeInfo.surplusAmount}` != 'null'">
              剩余
              <span style="color: #76b0eb;">{{isServeTypeInfo.surplusAmount}}</span>
              次
            </p>
            <!-- <p class="label">业务类型</p>
            <p class="val" v-if="form.status == 2">{{form.typeName}}</p>
            <el-select v-else v-model="form.businessType" filterable placeholder="请选择业务类型">
              <el-option
                v-for="item in typeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select> -->
          </div>
          <div class="col-box">
            <!-- <p class="label">服务类型</p>
            <p class="val" v-if="form.status == 2">{{form.typeName}}</p>
            <el-select v-else v-model="form.type" filterable placeholder="请选择业务类型">
              <el-option
                v-for="item in isTypeInfo.childs"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>  form.caseNode
            </el-select> -->
            <p class="label">创建日期</p>
            <p class="val">{{form.createTime2}}</p>
          </div>
        </div>
        <div class="row-box" v-if="form.businessType === 34">
          <div class="col-box">
            <p class="label">案件节点</p>
            <el-select v-model="form.caseNode" filterable placeholder="请选择案件节点">
              <el-option
                v-for="item in caseNodeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="row-box">
          <div class="col-box in-col-box">
            <p class="label">任务内容</p>
            <p class="val val2">{{form.content}}</p>
          </div>
        </div>
        <div class="row-box">
          <div class="col-box in-col-box">
            <p class="label">处理结果</p>
            <el-input
              type="textarea"
              :autosize="{ minRows: 4,}"
              placeholder="请输入处理结果"
              :disabled="form.status == 2"
              v-model="form.result">
            </el-input>
          </div>
        </div>
        <div class="row-box">
          <div class="col-box in-col-box">
            <p class="label">备注</p>
            <el-input
              type="textarea"
              :autosize="{ minRows: 3,}"
              placeholder="请输入备注"
              :disabled="form.status == 2"
              v-model="form.remark">
            </el-input>
          </div>
        </div>
        <div class="btns-box">
          <button class="btn" @click="cancel">取 消</button>
          <button class="btn2" @click="submit('结束任务')" v-if="form.status == 1">结 束 任 务</button>
          <button class="btn2" @click="submit" v-if="form.status != 2">保 存</button>
        </div>
      </div>
      <div class="right-box">
        <div class="top-box">
          <p class="title">处理日志（日志来源服务记录）</p>
          <button :class="`btn ${form.customerId ? '':'no-btn'}`" @click="add" v-if="form.status != 2"><span>+</span>新增日志</button>
        </div>
        <div class="log-row-box" style="cursor:pointer;" @click="lookServe(item)" v-for="(item,index) in form.serviceRecords" :key="item.id">
          <span class="circle"></span>
          <p class="date">{{item.createTime}}</p>
          <p class="name-val">
            {{item.principal}}
            <span v-if="form.status == 2 && index == form.serviceRecords.length - 1">结束任务</span>
            <span v-else>处理结果更新</span>
          </p>
          <p class="content-val">{{item.content}}</p>
        </div>
        <div class="log-row-box">
          <span class="circle"></span>
          <p class="date">{{form.createTime}}</p>
          <p class="name-val">
            {{form.customerName}}
            <span>创建任务</span>
          </p>
        </div>
      </div>
  </div>
</template>

<script>
import { editAssignment, getAssignmentDetail, getCustomerList } from "@/FackApi/api/project.js";
import { getSelectList } from "@/FackApi/api/index.js";
import addServe from '../UiBadges/addServe.vue';
export default {
  name: "editTask",
  components: {
    addServe,
  },
  props: {
    info: {
      type: Object,
    },
  },
  data() {
    return {
      form: {},
      clientList: [],
      typeList: [],
      isTypeInfo: {}, 
      caseNodeList: this.$getCaseNodeList(), // 案件节点
      isServeTypeInfo: {surplusAmount: null}, // 选择的服务类型
    };
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    init() {
      this.getAssignmentDetailInfo();
      this.getCustomerListInfo();
    },
    // 获取任务类型列表
    async getSelectListInfo() {
      // console.log(this.form,'this.form');
      let res = await getSelectList(this.form.customerId);
      if (res.code == 0) {
        // console.log(res,'业务类型');
        this.typeList = res.items;
        for (let i = 0; i < this.typeList.length; i++) {
          let item = this.typeList[i];
          if (item.id === this.form.businessType) {
            this.isTypeInfo = item;
          }
        }
      }
    },
    // 获取客户列表
    async getCustomerListInfo() {
      let res2 = await getCustomerList();
      if (res2.code == 0) {
        console.log(res2);
        this.clientList = res2.items;
      }
    },
    // 选择客户
    selectCustomer() {
      // console.log(this.form.customerId);
      this.form.businessType = "";
      this.form.type = "";
      this.isTypeInfo = {childs: []};
      for (let i = 0; i < this.clientList.length; i++) {
        let item = this.clientList[i];
        if (item.id == this.form.customerId) {
          this.form.contact = item.contact;
          break;
        }
      }
      console.log(this.form);
      this.getSelectListInfo();
    },
    // // 选择客户
    // selectClient() {
    //   this.form.type.val = "";
    //   this.form.serveType.val = "";
    //   this.isTypeInfo = {childs: []};
    //   this.getSelectListInfo();
    // },
    // 选择业务类型
    selectType() {
      this.form.type = "";
      this.isTypeInfo = {childs: []};
      for (let i = 0; i < this.typeList.length; i++) {
        let item = this.typeList[i];
        if (item.id == this.form.businessType) {
          this.isTypeInfo = item;
          break;
        }
      }
    },
    // 选择的服务类型 
    selectServeType() {
      // console.log(this.form.type);
      this.isServeTypeInfo = {surplusAmount: null};
      for (let i = 0; i < this.isTypeInfo.childs.length; i++) {
        let item = this.isTypeInfo.childs[i];
        if (item.id == this.form.type) {
          this.isServeTypeInfo = item;
          break;
        }
      }
      // console.log(this.isServeTypeInfo);
    },
    // 获取任务详情
    async getAssignmentDetailInfo() {
      let res = await getAssignmentDetail(this.info.info.id);
      if (res.code == 0) {
        // console.log(res.item,'任务详情');
        this.form = res.item;
        // this.form.caseNode = this.form.caseNode || '';
        if (this.form.caseNode === undefined) {
          this.$set(this.form,"caseNode","")
        }
        this.form.createTime2 = this.form.createTime.split(' ')[0];
        this.getSelectListInfo();
        let that = this;
        // console.log(this.form,'this.formv');
        setTimeout(() => {
          that.selectServeType();
        },300)
      }
    },
    // 保存
    async submit(str) {
      let verifyObj = {
        customerId: "客户名称",
        name: "项目名称",
        businessType: "业务类型",
        type: "服务类型",
        // caseNode: "案件节点",
        content: "任务内容",
      };
      for (let key in verifyObj) {
        if (!this.form[key]) {
          this.$message.warning(`${verifyObj[key]}不能为空！`);
          return;
        }
      }
      console.log(this.form.businessTypeName);
      if (this.form.businessType === 34 && !this.form.caseNode) {
        this.$message.warning(`案件节点不能为空！`);
        return;
      }
      // if (!this.form.customerId) {
      //   this.$message.warning(`客户名称不能为空！`);
      //   return;
      // }
      // if (!this.form.type) {
      //   this.$message.warning(`任务类型不能为空！`);
      //   return;
      // }
      if (!this.form.result && str == '结束任务') {
        this.$message.warning(`处理结果不能为空！`);
        return;
      }
      let params = {
        id: this.form.id,
        name: this.form.name,
        businessType: this.form.businessType,
        type: this.form.type,
        customerId: this.form.customerId,
        caseNode: this.form.caseNode,
        content: this.form.content,
        remark: this.form.remark,
        isAgent: this.form.isAgent,
        agent: this.form.agent,
        result: this.form.result,
        status: this.form.status,
      };
      if (str == '结束任务') {
        // console.log('结束任务'); 
        params.status = 2;
        params.finishedTime = this.$getDate(2);
      }
      // console.log(params);return
      let res2 = await editAssignment(params);
      // console.log(res2);
      if (res2.code == 0) {
        this.$parent.getTaskListInfo();
        this.cancel();
      }
    },
    // 取消
    cancel() {
      this.$parent.showTable = true;
    },
    // 新增服务
    add() {
      // console.log(this.info.info,'this.info.info');
      if (!this.form.customerId) return
      this.$router.push({name: 'core.badges', params: { info: this.info.info, type: 'add' } })
    },
    // 编辑服务
    lookServe(item) {
      // console.log(item);
      this.$router.push({name: 'core.badges', params: { info: item, type: 'edit' } })
    }
  },
};
</script>

<style lang="less" scoped>
.edit-task {
  display: flex;
  justify-content: space-between;
  margin: 16px 15px 30px 15px;
  height: 100%;
  // width: 100%;
  .left-box {
    width: 60%;
    background-color: #fff;
    border-radius: 18px;
    box-shadow: 0px 0px 10px 0px rgba(152, 172, 190, 0.2);
    padding: 26px;
    .row-box {
      display: flex;
      justify-content: space-between;
      .col-box {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        .label {
          width: 72px;
          font-size: 14px;
          color: #909399;
          white-space:nowrap;
        }
        .el-input,
        .el-select {
          width: 200px;
        }
        .val {
          display: flex;
          align-items: center;
          min-width: 200px;
          font-size: 14px;
          color: #333;
            span {
              display: block;
              width: 48px;
              height: 22px;
              line-height: 20px;
              text-align: center;
              font-size: 10px;
              color: #6474C7;
              border-radius: 25px;
              border: 1px solid #6474C7;
              margin-left: 20px;
            }
        }
        .val2 {
          flex: 1;
          align-items: initial;
          min-height: 100px;
          padding: 10px;
          border-radius: 10px;
          border: 1px solid #D7DBDA;
          // margin-left: 16px;
        }
        /deep/ .el-textarea {
          flex: 1;
          .el-textarea__inner {
            border-radius: 10px;
          }
        }
      }
      .in-col-box {
        flex: 1;
      }
    }
  }

  .right-box {
    // width: 38%;
    // height: 100%;
    flex: 1;
    background-color: #fff;
    border-radius: 18px;
    box-shadow: 0px 0px 10px 0px rgba(152, 172, 190, 0.2);
    padding: 26px;
    margin-left: 30px;
    .top-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      .title {
        font-size: 14px;
        color: #909399;
      }
      .btn {
        display: flex;
        align-items: center;
        width: 110px;
        height: 40px;
        font-size: 14px;
        color: #6474C7;
        background-color: #f1f3fb;
        cursor: pointer;
        span {
          font-size: 24px;
          margin-right: 10px;
        }
      }
      .no-btn {
        color: #ccc;
        cursor: not-allowed;
      }
    }
    .log-row-box {
      position: relative;
      padding: 0 0 30px 16px;
      border-left: 4px solid #EEF1F7;
      .circle {
        position: absolute;
        top: 0;
        left: -8px;
        display: block;
        width: 14px;
        height: 14px;
        border-radius: 8px;
        background-color: #fff;
        border: 4px solid #b9b9ce;
        box-sizing: border-box;
      }
      .date {
        line-height: 14px;
        font-size: 14px;
        color: #606266;
      }
      .name-val {
        font-size: 14px;
        color: #333;
        margin-top: 4px;
        span {
          color: #6474C7;
          margin-left: 20px;
        }
      }
      .content-val {
        line-height: 18px;
        margin-top: 6px;
      }
    }
  }
  .right-box .log-row-box:last-child{
    border-left: 4px solid #fff;
  }
  .btns-box {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    button {
      width: 140px;
      height: 40px;
      font-size: 16px;
      font-weight: 600;
      color: #bbb;
      border-radius: 25px;
      background: #f1f2f1;
      border: none;
      margin-left: 20px;
      cursor: pointer;
    }
    .btn2 {
      color: #fff;
      background: linear-gradient(90deg, #6878c8 0%, #79baf2 100%);
      border: none;
    }
  }
}
</style>
