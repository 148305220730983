<template>
  <div :class="showTable || examineObj.type == 'add' ? 'Ui-typography' : ''">
    <div class="nav-box all-crumbs" v-if="showTable">项目管理 > 项目任务</div>
    <div v-if="showTable">
      <div class="top-box">
        <el-button @click="add">新增任务</el-button>
        <el-button class="btn2" @click="upTemplate">下载模板</el-button>
        <el-button class="btn1" @click="upload">
          <input
            class="inputFile"
            type="file"
            ref="file"
            @change="outClick"
            :accept="fileType"
          />
          上传任务
        </el-button>
        <span style="flex: 1"></span>
        <div class="date-box">
          <p>选择日期</p>
          <el-date-picker
            v-model="date"
            type="date"
            @change="changeDate"
            placeholder="选择日期">
          </el-date-picker>
        </div>
        <div class="ipt-box">
          <p>查询</p>
          <el-input
            v-model="keyword"
            @keyup.enter.native="changeDate"
            placeholder="请输入关键词">
          </el-input>
        </div>
        <el-button @click="download">导出列表</el-button>
      </div>
      <div class="form-box">
        <el-table :data="tableData" ref="table" style="width: 100%">
        <!-- <template v-for="(head, index) in headData">
          <el-table-column v-if="head.prop == 'id'" fixed="left" :align="head.align" :key="index" :prop="head.prop" :label="head.label" :width="head.width">
            <template slot-scope="scope">
              <div class="btn-box">
                <p @click="edit(scope.row)">查看</p>
                <p class="btn-p" @click="del(scope.row)">删除</p>
              </div>
            </template>
          </el-table-column>
          <el-table-column :align="head.align" v-else :key="index" fixed="right" :prop="head.prop" :label="head.label">
          </el-table-column>
        </template> -->
          <el-table-column
            type="index"
            label="序号"
            width="100">
          </el-table-column>
          <el-table-column prop="name" show-overflow-tooltip label="任务名称" show-overflow-tooltip width="200">
          </el-table-column>
          <el-table-column prop="customerName" show-overflow-tooltip label="客户名称" width="200">
          </el-table-column>
          <el-table-column label="客户类型" width="200">
            <template slot-scope="scope">
              <!-- {{scope.row.customerType == 1 ? '个人类' : '企业类'}} -->
              <span v-if="scope.row.customerType == 1">个人类</span>
              <span v-if="scope.row.customerType == 2">企业类</span>
            </template>
          </el-table-column>
          <el-table-column prop="typeName" label="任务类型" width="200">
          </el-table-column>
          <el-table-column prop="projectName" show-overflow-tooltip label="所属项目" width="200">
          </el-table-column>
          <el-table-column prop="agent" label="负责人" show-overflow-tooltip width="200">
          </el-table-column>
          <el-table-column prop="createTime" label="创建时间" show-overflow-tooltip width="200">
          </el-table-column>
          <el-table-column prop="content" label="任务内容" show-overflow-tooltip width="200">
          </el-table-column>
          <el-table-column prop="remark" label="备注" show-overflow-tooltip width="200">
          </el-table-column>
          <el-table-column label="服务时长" width="200">
            <template slot-scope="scope">
              <!-- {{scope.row.hour}} : {{scope.row.minutes}} :{{scope.row.seconds}} -->
              {{scope.row.hour < 10 ? `0${scope.row.hour}`:scope.row.hour}} &nbsp;:
              {{scope.row.minutes < 10 ? `0${scope.row.minutes}`:scope.row.minutes}} &nbsp;:
              {{scope.row.seconds < 10 ? `0${scope.row.seconds}`:scope.row.seconds}}
            </template>
          </el-table-column>
          <!-- <el-table-column prop="univalence" label="服务单价（元）" width="200">
          </el-table-column> -->
          <el-table-column prop="totalAmount" label="服务总价（元）" width="200">
          </el-table-column>
          <el-table-column prop="finishedTime" label="完成时间" show-overflow-tooltip width="200">
          </el-table-column>
          <el-table-column label="最新进展" width="200">
            <template slot-scope="scope">
              <span v-if="scope.row.status == 0">待开始</span>
              <span v-else-if="scope.row.status == 1">进行中</span>
              <span v-else-if="scope.row.status == 2">已结束</span>
            </template>
          </el-table-column>
          <el-table-column prop="updateTime" label="更新时间" show-overflow-tooltip width="200">
          </el-table-column>
          <el-table-column prop="id" class-name="in-column" label="操作" fixed="right" align="center" width="140">
            <template slot-scope="scope">
              <div class="btn-box">
                <p @click="edit(scope.row)">查看</p>
                <p class="btn-p" @click="del(scope.row)">删除</p>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 15, 20]"
        :page-size="interPage"
        layout="prev, pager, next, jumper"
        :total="dataSize"
        background
      ></el-pagination>
    </div>
    <addTask v-if="!showTable && examineObj.type == 'add'"></addTask>
    <editTask v-if="!showTable && examineObj.type == 'edit'" :info="examineObj"></editTask>
  </div>
</template>

<script>
import { core } from "../../config/pluginInit";
import addTask from './UiTypography/addTask.vue';
import editTask from './UiTypography/editTask.vue';
import { getTaskList, getTaskListFile, delAssignment, getSCountervicerecord } from "@/FackApi/api/project.js";
import { uploadFile } from "@/FackApi/api/index.js";
import { Loading } from "element-ui";
import { mapGetters } from 'vuex'
var OSS = require('ali-oss');
export default {
  name: "UiTypography",
  components: {
    addTask,
    editTask,
  },
  data() {
    return {
      currentPage: 1,
      interPage: 10,
      dataSize: 0,
      date: "",
      keyword: "",
      headData: [
        {prop: "name", label: "任务名称", align: "left", width: '150'},
        {prop: "customerName", label: "客户名称", align: "left", width: '200'},
        {prop: "customerType", label: "客户类型", align: "left", width: '200'},
        {prop: "typeName", label: "任务类型", align: "left", width: '200'},
        {prop: "agent", label: "负责人", align: "left", width: '200'},
        {prop: "createTime", label: "创建时间", align: "left", width: '200'},
        {prop: "content", label: "问题内容", align: "left", width: '300'},
        {prop: "remark", label: "备注", align: "left", width: '300'},
        {prop: "totalDuration", label: "服务时长（分钟）", align: "left", width: '300'},
        {prop: "totalAmount", label: "价格（元）", align: "left", width: '200'},
        {prop: "finishedTime", label: "完成时间", align: "left", width: '200'},
        {prop: "status", label: "最新进展", align: "left", width: '200'},
        {prop: "updateTime", label: "更新时间", align: "left", width: '200'},
        {prop: "id", label: "操作", align: "center", width: '200'},
      ],
      tableData: [],
      showTable: true,
      examineObj: { // 查看任务信息
        type: '',
      },
      fileType: '.csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,',
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...mapGetters({
      ossInfo: 'index/ossInfoState',
    }),
  },
  mounted() {
    core.index();
  },
  methods: {
    init() {
      this.getTaskListInfo();
      // console.log(this.$getTaskType(typeList,));
    },
    // 获取任务列表
    async getTaskListInfo() {
      let loadingInstance = Loading.service({ fullscreen: true });
      let params = `${this.currentPage}/${this.interPage}`;
      if (this.date || this.keyword) {
        params += '?'
      }
      if (this.date) {
        params += `&date=${this.date}`
      }
      if (this.keyword) {
        params += `&queryString=${this.keyword}`
      }
      let res3 = await getTaskList(params);
      // console.log(res3);
      loadingInstance.close();
      if (res3.code == 0) {
        this.tableData = res3.items;
        this.dataSize = res3.totalCount;
        this.$nextTick(()=> {
          this.$refs.table.doLayout();
        })
      }
    },
    // 下载上传任务模板
    upTemplate() {
      let client = new OSS({
        region: 'oss-cn-shenzhen',
        accessKeyId: this.ossInfo.accessid,
        accessKeySecret: '5ZUDWAJ0Gz4o52kubRVYCaJRRQRims',
        bucket: 'ilaas-files',
      })
      let filename = '任务导入模板.xlsx';
      let response = {
		  	"content-disposition": `attachment; filename=${encodeURIComponent(filename)};expires: 7200`
      }
      // console.log(client); return
      let path = 'common/template/project/assignment/upload/任务导入模板.xlsx'
      let url = client.signatureUrl(path, {
				response,
			});
			window.location.href = url;
    },
    // 上传任务
    upload() {
      this.$refs.file.value = null;
      document.querySelector(".inputFile").click();
    },
    async outClick() {
      const inputFile = this.$refs.file.files[0];
      const size = this.$refs.file.files[0].size;
      if (size < 50 * 1024 * 1024) {
        const formData = new FormData();
        formData.append("file", inputFile);
        let res = await uploadFile(formData);
        if (res.code) {
          this.init();
          if (res.items.length != 0) {
            this.$message.warning(res.items[0]);
          }
        }
      } else {
        this.$message.warning("请选择小于50M的图片");
      }
    },
    // 导出任务
    async download() {
      let loadingInstance = Loading.service({ fullscreen: true });
      let params = ``;
      if (this.date || this.keyword) {
        params += '?'
      }
      if (this.date) {
        params += `&date=${this.date}`
      }
      if (this.keyword) {
        params += `&queryString=${this.keyword}`
      }
      let res3 = await getTaskListFile(params);
      console.log(res3);
      loadingInstance.close();
      let data = res3;
      const fileName = `项目任务（${this.$getDate(1)}）.xlsx`;
      const blob =
        data instanceof Blob
          ? data
          : new Blob([data], {
              type: 'application/vnd.ms-excel;charset=utf-8',
            });
      if ('download' in document.createElement('a')) {
        // 非IE下载
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.style.display = 'none';
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(link.href);
        document.body.removeChild(link);
      } else {
        // IE10+下载
        navigator.msSaveBlob(blob, fileName);
      }
    },
    // 选择日期
    changeDate() {
      this.date = this.$getDate(1,this.date);
      this.currentPage = 1;
      this.interPage = 10;
      this.getTaskListInfo();
    },
    add() {
      this.examineObj.type = 'add';
      this.showTable = false;
    },
    // 编辑任务
    edit(row) {
      this.examineObj.type = 'edit';
      this.examineObj.info = row;
      this.showTable = false;
      // console.log(row);
    },
    async del(row) {
      let params = {
        ids: [row.id]
      };
      let res3 = await getSCountervicerecord(params);
      if (res3.code == 0) {
        let val = `请确认是否删除任务【 ${row.name} 】？`
        if (res3.content != 0) {
          val = `任务【 ${row.name} 】下有 ${res3.content} 条服务记录，是否确认删除？`
        }
        this.$confirm(val)
          .then((_) => {
            this.delAssignmentInfo(row);
          })
          .catch((_) => {});
        }
    },
    async delAssignmentInfo(row) {
      let params = {
        ids: [row.id]
      };
      let res2 = await delAssignment(params);
      if (res2.code == 0) {
        this.getTaskListInfo();
      }
    },
    handleSizeChange(psize) {
      this.interPage = psize;
      this.getTaskListInfo();
    },
    handleCurrentChange(cpage) {
      this.currentPage = cpage;
      this.getTaskListInfo();
    },
  },
};
</script>

<style lang="less" scoped>
.Ui-typography {
  // width: 100%;
  display: flex;
  flex-direction: column;
  // height: 700px;
  position: relative;
  background-color: #fff;
  padding: 20px 30px 30px;
  margin: 10px 15px 30px 15px;
  border-radius: 18px;
  box-shadow: 0px 0px 10px 0px rgba(152, 172, 190, 0.2);
  .nav-box {
    height: 40px;
    width: 100%;
    background-color: #fff;
    margin-bottom: 20px;
  }
  /deep/ .top-box {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    .el-button {
      width: 100px;
      height: 40px;
      background: #fff;
      border-radius: 8px;
      border: 1px solid #6474c7;
      font-size: 14px;
      color: #6474c7;
      line-height: 16px;
    }
    .el-button:hover {
      color: #fff;
      background: linear-gradient(90deg, #6878c8 0%, #79baf2 100%);
      border: 1px solid #bbd2ff;
    }
    .btn1 {
      margin-right: 20px;
      line-height: 0px;
      .inputFile {
        width: 1px;
        height: 1px;
        border-radius: 80px;
      }
    }
    .btn2 {
      margin: 0 20px;
    }
    p {
      font-size: 14px;
      color: #333;
      margin-right: 10px;
      white-space: nowrap;
    }
    .date-box {
      display: flex;
      align-items: center;
      .el-date-editor {
        width: 180px;
        height: 40px;
        .el-input__inner {
          height: 100%;
          font-size: 14px;
        }
        .el-input__prefix {
          .el-input__icon {
            line-height: 40px;
            font-size: 22px;
          }
        }
      }
    }
    .ipt-box {
      display: flex;
      align-items: center;
      margin: 0 20px 0 40px;
      .el-input {
        .el-input__inner {
          width: 240px;
          height: 40px;
          font-size: 14px;
          background: #eaf5ff;
          border-radius: 10px;
          border: none;
        }
      }
    }
  }
  .form-box {
    flex: 1;
    /deep/ .el-table {
      border-radius: 18px 18px 0 0;
      .el-table__header-wrapper {
        .el-table__header {
          .has-gutter {
            th {
              font-size: 14px;
              font-weight: bold;
              color: #6474C7;
              padding: 12px 16px;
              background: #EAF5FF;
            }
            // .in-column {
            //   color: #fff;
            //   background: linear-gradient(90deg, #6878C8 0%, #79BAF2 100%);
            // }
          }
        }
      }
      .el-table__body-wrapper {
        min-height: 470px;
        .el-table__body {
          td {
            font-size: 14px;
            color: #333;
            padding: 12px 16px;
            .cell {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
          .in-column {
            .cell {
              .btn-box {
                display: flex;
                justify-content: space-between;
                p {
                  font-size: 14px;
                  color: #6474C7;
                  cursor:pointer;
                }
                .btn-p {
                  color: #FCC647;
                }
              }
            }
          }
        }
      }
      .el-table__fixed-right {
        thead {
          .is-center {
            color: #fff;
            background: linear-gradient(90deg, #6878C8 0%, #79BAF2 100%) !important;
          }
        }
          .in-column {
            .cell {
              .btn-box {
                display: flex;
                justify-content: space-between;
                p {
                  font-size: 14px;
                  color: #6474C7;
                  cursor:pointer;
                }
                .btn-p {
                  color: #FCC647;
                }
              }
            }
          }
      }
    }
  }
  .el-pagination {
    align-items: initial;
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}
</style>
