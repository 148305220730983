<template>
  <div class="add-task">
    <div class="row-box">
      <p class="label">
        <span>* </span>
        任务名称
      </p>
      <el-input type="text" v-model="form.name.val" :disabled="form.name.status" placeholder="请输入任务名称"/>
    </div>
    <!-- <div class="row-box">
      <p class="label">
        <span>* </span>
        任务类型
      </p>
      <el-select v-model="form.type.val" filterable :disabled="form.type.status" placeholder="请选择服务类型">
        <el-option
          v-for="item in typeList"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </div> -->
    <div class="row-box row-box2">
      <p class="label">
        <span>* </span>
        任务客户
      </p>
      <el-select v-model="form.customerId.val" @change="selectClient" filterable :disabled="form.customerId.status" placeholder="请选择任务客户">
        <el-option
          v-for="item in clientList"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <p class="btn-p" @click="addClient">快速添加客户</p>
    </div>
    <div class="row-box">
      <p class="label">
        <span>* </span>
        业务类型
      </p>
      <el-select v-model="form.type.val" @change="selectType" filterable :disabled="form.type.status || !form.customerId.val" placeholder="请选择业务类型">
        <el-option
          v-for="item in typeList"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </div>
    <div class="row-box row-box2" v-if="isTypeInfo.childs.length > 0">
      <p class="label">
        <span>* </span>
        服务类型
      </p>
      <el-select v-model="form.serveType.val" @change="selectServeType" filterable :disabled="form.serveType.status" placeholder="请选择服务类型">
        <el-option
          v-for="item in isTypeInfo.childs"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <p class="btn-p" style="cursor:default;color:#666;" v-if="`${isServeTypeInfo.surplusAmount}` != 'null'">
        剩余
        <span style="color: #76b0eb;">{{isServeTypeInfo.surplusAmount}}</span>
        次
      </p>
    </div>
    <div class="row-box">
      <p class="label">
        <span>* </span>
        任务内容
      </p>
      <el-input
        type="textarea"
        :autosize="{ minRows: 4 }"
        :disabled="form.content.status"
        placeholder="请输入任务内容"
        v-model="form.content.val"
      >
      </el-input>
    </div>
    <!-- <div class="row-box">
      <p class="label">归属项目</p>
      <el-select v-model="form.project.val" :disabled="form.project.status" placeholder="请选择归属项目">
        <el-option
          v-for="item in projectList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div> -->
    <div class="row-box">
      <p class="label">
        <span>* </span>
        是否代理
      </p>
      <el-radio-group v-model="radio">
        <el-radio :label="true">是</el-radio>
        <el-radio :label="false">否</el-radio>
      </el-radio-group>
    </div>
    <div class="row-box" v-if="radio == 1">
      <p class="label">
        <span>* </span>
        代理人
      </p>
      <el-input
        type="text"
        v-model="form.agent.val"
        :disabled="form.agent.status"
        placeholder="请输入代理人姓名"
      />
    </div>
    <span style="flex: 1;"></span>
    <div class="btns-box">
      <button class="btn" @click="cancel">取消</button>
      <button class="btn2" @click="addSubmit">保存</button>
    </div>
    <addClientDialog v-if="showClientDialog"></addClientDialog>
    <!-- <el-dialog
      title="快速添加客户"
      :visible.sync="dialogVisible"
      center
      :show-close="false"
      width="700px">
      <div class="content-box">
        <div class="row-box">
          <p class="label">*客户类型</p>
          <el-select
              v-model="clientForm.type"
              placeholder="请选择客户类型"
            >
              <el-option
                v-for="item in typeList2"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
        </div>
        <div class="row-box">
          <p class="label">*客户名称</p>
          <el-input
            type="text"
            v-model="clientForm.name"
            placeholder="请输入客户名称"
          />
        </div>
        <div class="row-box">
          <p class="label">*联系方式</p>
          <el-input
            type="text"
            v-model="clientForm.phone"
            placeholder="请输入联系方式"
          />
        </div>
        <div class="row-box">
          <p class="label">客户编号</p>
          <el-input
            type="text"
            v-model="clientForm.num"
            placeholder="请输入客户编号"
          />
        </div>
      </div>
      <div class="btns-box">
        <button class="btn" @click="cancel2">取 消</button>
        <button class="btn2" @click="submit2">保 存</button>
      </div>
    </el-dialog> -->
  </div>
</template>

<script>
import addClientDialog from '@/views/Dialog/addClientDialog';
import { getCustomerList, addAssignment, editAssignment } from "@/FackApi/api/project.js";
import { getSelectList } from "@/FackApi/api/index.js";
export default {
  name: "addTask",
  components: {
    addClientDialog,
  },
  props: {
    info: {
      type: Object,
    },
  },
  data() {
    return {
      form: {
        name: {
          status: false,
          val: '',
        },
        type: {
          status: false,
          val: '',
        },
        serveType: {
          status: false,
          val: '',
        },
        customerId: {
          status: false,
          val: '',
        },
        content: {
          status: false,
          val: '',
        },
        // project: {
        //   status: false,
        //   val: '',
        // },
        agent: {
          status: false,
          val: '',
        },
      },
      typeList: [], // 业务类型
      isTypeInfo: {childs: []}, // 选择的业务类型
      isServeTypeInfo: {surplusAmount: null}, // 选择的服务类型
      clientList: [],
      projectList: [],
      radio: true,
      type: 'add',
      showClientDialog: false,
      throttleNum: 0,
      flag: null, // 计时器
      isClientInfo: {}, // 选择的客户
    };
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    init() {
      // this.getSelectListInfo();
      this.getCustomerListInfo();
      // if (this.info.type == 'edit') {
      //   this.setEditInfo();
      // }
    },
    // 获取任务类型列表
    async getSelectListInfo() {
      console.log(this.form);
      let res = await getSelectList(this.form.customerId.val);
      if (res.code == 0) {
        // console.log(res,'业务类型');
        this.typeList = res.items;
        if (this.isClientInfo.type == 1) {
          this.typeList.splice(0,1);
        }
      }
    },
    // 获取客户列表
    async getCustomerListInfo() {
      let res2 = await getCustomerList();
      if (res2.code == 0) {
        // console.log(res);
        this.clientList = res2.items;
      }
    },
    // 选择客户
    selectClient() {
      // console.log(this.clientList);
      // console.log(form.customerId.val);
      this.form.type.val = "";
      this.form.serveType.val = "";
      this.isTypeInfo = {childs: []};
      // this.isClientInfo
      for (let i = 0; i < this.clientList.length; i++) {
        let item = this.clientList[i];
        if (item.id == this.form.customerId.val) {
          this.isClientInfo = item;
          break;
        }
      }
      this.getSelectListInfo();
    },
    // 选择业务类型
    selectType() {
      this.form.serveType.val = "";
      this.isTypeInfo = {childs: []};
      this.isServeTypeInfo = {surplusAmount: null};
      for (let i = 0; i < this.typeList.length; i++) {
        let item = this.typeList[i];
        if (item.id == this.form.type.val) {
          this.isTypeInfo = item;
          break;
        }
      }
    },
    // 选择的服务类型 
    selectServeType() {
      this.isServeTypeInfo = {surplusAmount: null};
      for (let i = 0; i < this.isTypeInfo.childs.length; i++) {
        let item = this.isTypeInfo.childs[i];
        if (item.id == this.form.serveType.val) {
          this.isServeTypeInfo = item;
          break;
        }
      }
    },
    // 编辑数据
    // setEditInfo() {
    //   let data = this.info.info;
    //   for (let key in this.form) {
    //     this.form[key].val = data[key];
    //   }
    //   this.radio = data.isAgent;
    // },
    // 保存
    // submit() {
    //   let verifyObj = {
    //     name: "任务名称",
    //     type: "任务类型",
    //     customerId: "任务客户",
    //     content: "任务内容",
    //   };
    //   for (let key in verifyObj) {
    //     if (!this.form[key].val) {
    //       this.$message.warning(`${verifyObj[key]}不能为空！`);
    //       return;
    //     }
    //   }
    //   if (this.radio && !this.form.agent.val) {
    //     this.$message.warning(`代理人姓名不能为空！`);
    //     return;
    //   }
    //   let params = {
    //     name: this.form.name.val,
    //     type: this.form.type.val,
    //     customerId: this.form.customerId.val,
    //     content: this.form.content.val,
    //     remark: "",
    //     isAgent: this.radio,
    //     agent: this.radio ? this.form.agent.val : '',
    //   };
    //   if (this.info.type == 'add') {
    //     this.addSubmit(params);
    //   } else if (this.info.type == 'edit') {
    //     this.editSubmit(params);
    //   }
    // },
    // 创建任务
    async addSubmit() {
      if (this.throttleNum != 0) return;
      this.throttleNum = 1;
      let that = this;
      this.flag = setInterval(()=>{
        that.throttleNum ++;
        if (that.throttleNum == 2) {
          that.throttleNum = 0;
          clearInterval(this.flag);
        }
      },1000)
      let verifyObj = {
        name: "任务名称",
        customerId: "任务客户",
        type: "业务类型",
        serveType: "服务类型",
        content: "任务内容",
      };
      for (let key in verifyObj) {
        if (!this.form[key].val) {
          this.$message.warning(`${verifyObj[key]}不能为空！`);
          return;
        }
      }
      // if (this.form.type.val === 1 && !this.form.serveType.val) {
      //   this.$message.warning(`服务类型不能为空！`);
      //     return;
      // }
      if (this.radio && !this.form.agent.val) {
        this.$message.warning(`代理人姓名不能为空！`);
        return;
      }
      // this.form.type.val===1为常年法律顾问
      let params = {
        name: this.form.name.val,
        businessType: this.form.type.val,
        type: this.form.type.val ? this.form.serveType.val:'',
        customerId: this.form.customerId.val,
        content: this.form.content.val,
        remark: "",
        isAgent: this.radio,
        agent: this.radio ? this.form.agent.val : '',
      };
      // console.log(params);return
      let res3 = await addAssignment(params);
      if (res3.code == 0) {
        this.$parent.getTaskListInfo();
        this.cancel();
      }
    },
    // 编辑任务
    // async editSubmit(params) {
    //   params.id = this.info.info.id;
    //   let res4 = await editAssignment(params);
    //   if (res4.code == 0) {
    //     this.$parent.getTaskListInfo();
    //     this.cancel();
    //   }
    // },
    // 取消
    cancel() {
      this.$parent.showTable = true;
    },
    // 新增客户
    addClient() {
      this.showClientDialog = true;
    },
  },
  beforeDestroy() {
    clearInterval(this.flag);
  },
};
</script>

<style lang="less" scoped>
.add-task {
  display: flex;
  flex-direction: column;
  // height: 100%;
  height: 70vh;
  /deep/ .row-box {
    width: 50%;
    display: flex;
    // align-items: center;
    margin-bottom: 14px;
    .label {
      min-width: 72px;
      font-size: 14px;
      line-height: 40px;
      color: #909399;
      margin-right: 14px;
      white-space: nowrap;
      span {
        color: red;
      }
    }
    .el-radio-group {
      display: flex;
      align-items: center;
    }
    .el-input {
      flex: 1;
      height: 40px;
      font-size: 14px;
      background: #fff;
      border-radius: 10px;
      box-sizing: border-box;
      input {
        height: 100%;
        width: 100%;
        border-radius: 10px;
      }
    }
    .el-textarea {
      .el-textarea__inner {
        font-size: 14px;
        border-radius: 10px;
      }
    }
    .el-select {
      flex: 1;
      .el-input {
        width: 100%;
        .el-input__inner {
          width: 100%;
        }
      }
    }
    .el-radio-group {
      .el-radio {
        margin-bottom: 0;
        .el-radio__label {
          font-size: 14px;
        }
      }
      .is-checked {
        .is-checked {
          .el-radio__inner {
            border-color: #6474c7;
            background-color: #6474c7;
          }
        }
        .el-radio__label {
          color: #6474c7;
        }
      }
    }
  }
  .row-box2 {
    position: relative;
    .btn-p {
      position: absolute;
      top: 50%;
      right: -120px;
      color: #76b0eb;
      transform: translate(0, -50%);
      cursor: pointer;
    }
  }
  .btns-box {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    button {
      width: 140px;
      height: 40px;
      font-size: 16px;
      font-weight: 600;
      color: #bbb;
      border-radius: 25px;
      background: #f1f2f1;
      border: none;
      margin-left: 20px;
      cursor: pointer;
    }
    .btn2 {
      color: #fff;
      background: linear-gradient(90deg, #6878c8 0%, #79baf2 100%);
      border: none;
    }
  }
}
</style>
