<template>
  <div class="add-serve">
    <p class="all-crumbs">项目管理 > 服务记录</p>
    <div class="row-box">
      <p class="label">
        <span>* </span>
        服务名称
      </p>
      <el-input
        type="text"
        v-model="form.name"
        :disabled="false"
        placeholder="请输入服务名称"
      />
    </div>
    <div class="row-box">
      <p class="label">
        <span>* </span>
        开始时间
      </p>
      <el-date-picker
        v-model="form.startTime"
        @change="setStartDate"
        type="datetime"
        placeholder="选择服务开始时间">
      </el-date-picker>
    </div>
    <div class="row-box">
      <p class="label">
        <span>* </span>
        服务时长
      </p>
      <div class="ipt2">
        <el-input v-model="form.hour" @blur="test2(form.hour,'时')" placeholder="时"></el-input>
        <span>:</span>
        <el-input v-model="form.minutes" @blur="test2(form.minutes,'分')" placeholder="分"></el-input>
        <span>:</span>
        <el-input v-model="form.seconds" @blur="test2(form.seconds,'秒')" placeholder="秒"></el-input>
        <!-- <span style="flex:1;"></span>
        <p>小时</p> -->
      </div>
    </div>
    <div class="row-box row-box2">
      <p class="label">
        <span>* </span>
        服务客户
      </p>
      <el-select
        v-model="form.customerId"
        filterable
        :disabled="false"
        @change="selectCustomer"
        placeholder="请选择服务客户"
      >
        <el-option
          v-for="item in clientList"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <p class="btn-p" @click="addClient">快速添加客户</p>
    </div>
    <div class="row-box row-box2">
      <p class="label">
        <span>* </span>
        服务任务
      </p>
      <el-select
        v-model="form.assignmentId"
        @change="selectTask"
        filterable
        :disabled="!form.customerId"
        placeholder="请选择服务任务"
      >
        <el-option
          v-for="item in unfinshedTaskList"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <p :class="`btn-p ${form.customerId ? '':'btn-p2'}`" @click="addTask">快速创建任务</p>
    </div>
    <div class="row-box">
      <p class="label">
        业务类型
      </p>
      <el-input v-model="isSelectTaskInfo.businessTypeName" disabled></el-input>
    </div>
    <div class="row-box">
      <p class="label">
        <span>* </span>
        服务内容
      </p>
      <el-input
        type="textarea"
        :autosize="{ minRows: 4 }"
        :disabled="false"
        placeholder="请输入服务内容"
        v-model="form.content"
      >
      </el-input>
    </div>
    <!-- <div class="row-box row-box2">
      <p class="label">
        <span>* </span>
        服务任务
      </p>
      <el-select
        v-model="form.assignmentId"
        filterable
        :disabled="!form.customerId"
        placeholder="请选择服务任务"
      >
        <el-option
          v-for="item in unfinshedTaskList"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <p :class="`btn-p ${form.customerId ? '':'btn-p2'}`" @click="addTask">快速创建任务</p>
    </div> -->
    <template v-if="isSelectTaskInfo.businessTypeName == '代理案件'">
      <div class="row-box">
        <p class="label">
          <span>* </span>
          案件节点
        </p>
        <el-select v-model="form.caseNode" filterable placeholder="请选择案件节点">
          <el-option
            v-for="item in caseNodeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="row-box">
        <p class="label">
          <span>* </span>
          是否结案
        </p>
        <el-radio-group v-model="form.isClosed">
          <el-radio :label="true">是</el-radio>
          <el-radio :label="false">否</el-radio>
        </el-radio-group>
      </div>
    </template>
    <template v-if="form.isClosed">
      <div class="row-box">
        <p class="label">
          <span>* </span>
          结案结果
        </p>
        <el-select v-model="form.closedResult" filterable placeholder="请选择结案结果">
          <el-option
            v-for="item in caseResultList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="row-box">
        <p class="label">
          <span>* </span>
          结案节点
        </p>
        <el-select v-model="form.closedNode" filterable placeholder="请选择结案节点">
          <el-option
            v-for="item in caseSettleList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="row-box">
        <p class="label">
          <span>* </span>
          结案内容
        </p>
        <el-input
          type="textarea"
          :autosize="{ minRows: 4 }"
          :disabled="false"
          placeholder="请输入结案内容"
          v-model="form.closedContent"
        >
        </el-input>
      </div>
    </template>
    <div class="row-box" v-if="isSelectTaskInfo.businessTypeName == '代理案件'">
      <p class="label">
        <span>* </span>
        审理机构
      </p>
      <el-input
        type="text"
        v-model="form.hearCaseOrganization"
        :disabled="false"
        placeholder="请输入审理案件机构"
      />
    </div>
    <div class="row-box">
      <p class="label">负责人</p>
      <el-input
        type="text"
        v-model="form.principal"
        :disabled="false"
        placeholder="请输入负责人姓名"
      />
    </div>
    <div class="row-box">
      <p class="label">
        <span>* </span>
        是否收费
      </p>
      <el-radio-group v-model="form.isNeedCharge">
        <el-radio :label="true">是</el-radio>
        <el-radio :label="false">否</el-radio>
      </el-radio-group>
    </div>
    <div class="row-box" v-if="form.isNeedCharge">
      <p class="label">
        <span>* </span>
        服务单价
      </p>
      <el-input class="ipt3" @blur="test" v-model="form.univalence" />
      <p class="label label-cost" v-if="form.isNeedCharge">
          总价:
          <span style="color: #333;">{{totalCost}} 元</span>
        </p>
    </div>
    <span style="flex: 1"></span>
    <div class="btns-box">
      <button class="btn" @click="cancel">取消</button>
      <button class="btn2" @click="addSubmit">保存</button>
    </div>
    <addClientDialog v-if="showClientDialog"></addClientDialog>
    <addTaskDialog v-if="showTaskDialog" :info="taskInfo"></addTaskDialog>
  </div>
</template>

<script>
import addTaskDialog from '@/views/Dialog/addTaskDialog.vue'
import addClientDialog from '@/views/Dialog/addClientDialog';
import {
  getCustomerList,
  getUnfinshedTaskList,
  addServiceRecord,
  editServiceRecord,
  getServicerecordDetail,
} from "@/FackApi/api/project.js";
import { string } from '@amcharts/amcharts4/core';
export default {
  name: "addServe",
  components: {
    addTaskDialog,
    addClientDialog,
  },
  props: {
    info: {
      type: Object,
    },
  },
  data() {
    return {
      form: {
        name: "",
        startTime: "",
        duration: "",
        hour: "00",
        minutes: "00",
        seconds: "00",
        customerId: "",
        content: "",
        assignmentId: "",
        principal: "",
        isNeedCharge: true,
        univalence: "",
        caseNode: "", // 案件节点
        isClosed: false, // 是否结案
        closedResult: "", // 结案结果
        closedNode: "", // 结案节点
        closedContent: "", // 结案内容
        hearCaseOrganization: "", // 审理案件机构
      },
      disabledInfo: {},
      typeList: [],
      clientList: [],
      projectList: [],
      unfinshedTaskList: [],
      radio: true,
      type: "add",
      totalCost: 0,
      showTaskDialog: false,
      showClientDialog: false,
      taskInfo: null,
      isSelectTaskInfo: {}, // 选择项目任务
      throttleNum: 0,
      flag: null, // 计时器
      caseNodeList: this.$getCaseNodeList(), // 案件节点
      caseResultList: this.$getCaseResultList(), // 案件结果
      caseSettleList: this.$getCaseSettleList(), // 结案节点
    };
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    init() {
      this.getCustomerListInfo();
      if (this.info.type == 'edit') {
        this.getServicerecordDetailInfo();
      }
      // console.log(this.info);
      if (this.$route.params.type == 'add') {
        // console.log(this.$route.params.info);
          this.form.customerId = this.$route.params.info.customerId;
          this.form.assignmentId = this.$route.params.info.id;
          this.getSelectListInfo();
          let that = this;
          setTimeout(() => {
            that.selectTask();
          },300)
      }
      if (this.showTaskDialog) {
        this.getSelectListInfo();
      }
    },
    // 获取未完成任务列表
    async getSelectListInfo() {
      let res = await getUnfinshedTaskList(`?customerId=${this.form.customerId}`);
      if (res.code == 0) {
        this.unfinshedTaskList = res.items;
      }
    },
    // 获取客户列表
    async getCustomerListInfo() {
      let res2 = await getCustomerList();
      if (res2.code == 0) {
        this.clientList = res2.items;
      }
    },
    // 获取详情数据
    async getServicerecordDetailInfo() {
      let res4 = await getServicerecordDetail(this.info.row.id);
      if (res4.code == 0) {
        let data = res4.item;
        this.form = {
          name: data.name,
          startTime: data.startTime,
          hour: (data.hour+'').length == 1 ? `0${data.hour}`: data.hour,
          minutes: (data.minutes+'').length == 1 ? `0${data.minutes}`: data.minutes,
          seconds: (data.seconds+'').length == 1 ? `0${data.seconds}`: data.seconds,
          customerId: data.customerId,
          content: data.content,
          assignmentId: data.assignmentId,
          principal: data.principal,
          isNeedCharge: data.isNeedCharge,
          univalence: data.univalence,
          id: data.id,
          caseNode: data.caseNode, // 案件节点
          isClosed: data.isClosed, // 是否结案
          closedResult: data.closedResult, // 结案结果
          closedNode: data.closedNode, // 结案节点
          closedContent: data.closedContent, // 结案内容
          hearCaseOrganization: data.hearCaseOrganization, // 审理案件机构
        }
        this.totalCost = res4.item.totalAmount;
        if (this.form.customerId) {
          this.getSelectListInfo();
        }
        let that = this;
        setTimeout(()=>{
          that.selectTask();
        },300)
        // this.selectTask();
      }
      // console.log(this.form);
    },
    // 选择开始时间
    setStartDate() {
      // console.log(this.$getDate(2,this.form.startDate));
      this.form.startTime = this.$getDate(2,this.form.startTime)
    },
    // 单价失焦
    test() {
      if(!(/^([1-9]\d*|[0]{1,1})$/.test(this.form.univalence))){
        this.$message.warning(`单价应为正整数!`);
        this.form.univalence = '';
        return
      }
      this.calculateCost()
    },
    test2(num,type) {
      if(!(/^([1-9]\d*|[0]{1,1})$/.test(Number(num)))){
        this.$message.warning(`时间应为正整数!`);
        this.totalCost = 0;
        if (type == '时') {
          this.form.hour = '';
        } else if (type == '分') {
          this.form.minutes = '';
        } else {
          this.form.seconds = '';
        }
        return
      }
      if (type == '分' && Number(num) > 59) {
        this.$message.warning(`时间分最大为59!`);
        this.form.minutes = '';
        return
      }
      if (type == '秒' && Number(num) > 59) {
        this.$message.warning(`时间秒最大为59!`);
        this.form.seconds = '';
        return
      }
      this.calculateCost()
    },
    // 计算总价
    calculateCost() {
      let hour = Number(this.form.hour);
      let minutes = Number(this.form.minutes);
      let seconds = Number(this.form.seconds);
      let hourCost = hour * this.form.univalence;
      let minuteCost = (minutes / 60) * this.form.univalence;
      let secondCost = ((seconds / 60) / 60 ) * this.form.univalence;
      this.totalCost = (hourCost + minuteCost + secondCost).toFixed(2);
    },
    // 选择客户
    selectCustomer() {
      this.form.assignmentId = '';
      this.form.caseNode = '';
      this.form.isClosed = false;
      this.isSelectTaskInfo = {};
      this.$nextTick(() => {
        this.getSelectListInfo();
      })
    },
    // 选择服务任务
    selectTask() {
      // console.log(this.form);
      // console.log(this.unfinshedTaskList);
      for (let i = 0; i < this.unfinshedTaskList.length; i++) {
        let item = this.unfinshedTaskList[i];
        if (item.id === this.form.assignmentId) {
          this.isSelectTaskInfo = item;
          break
        }
      }
      // console.log(this.isSelectTaskInfo,'-----------');
    },
    // 创建任务
    async addSubmit() {
      if (this.throttleNum != 0) return;
      this.throttleNum = 1;
      let that = this;
      this.flag = setInterval(()=>{
        that.throttleNum ++;
        if (that.throttleNum == 2) {
          that.throttleNum = 0;
          clearInterval(this.flag);
        }
      },1000)
      let verifyObj = {
        name: "服务名称",
        startTime: "开始时间",
        duration: "服务时长",
        customerId: "服务客户",
        assignmentId: "服务任务",
        content: "服务内容",
      };
      if (this.form.hour || this.form.minutes || this.form.seconds) {
        this.form.duration = '11';
      }
      for (let key in verifyObj) {
        if (!this.form[key]) {
          this.$message.warning(`${verifyObj[key]}不能为空！`);
          return;
        }
      }
      if (this.isSelectTaskInfo.businessTypeName == "代理案件" && !this.form.caseNode) {
        this.$message.warning(`案件节点不能为空！`);
        return;
      }
      if (this.form.isClosed) {
        let verifyObj2 = {
          closedResult: "结案结果",
          closedNode: "结案节点",
          closedContent: "结案内容",
          // hearCaseOrganization: "审理机构",
        };
        for (let key in verifyObj2) {
          if (!this.form[key]) {
            this.$message.warning(`${verifyObj2[key]}不能为空！`);
            return;
          }
        }
      }
      if (this.isSelectTaskInfo.businessTypeName == "代理案件" && !this.form.hearCaseOrganization) {
        this.$message.warning(`审理机构不能为空！`);
        return;
      }
      if (this.form.isNeedCharge && !this.form.univalence) {
        this.$message.warning(`服务单价不能为空！`);
        return;
      }
      let params = {
        name: this.form.name,
        startTime: this.form.startTime,
        hour: Number(this.form.hour),
        minutes: Number(this.form.minutes),
        seconds: Number(this.form.seconds),
        customerId: this.form.customerId,
        content: this.form.content,
        assignmentId: this.form.assignmentId,
        principal: this.form.principal,
        isNeedCharge: this.form.isNeedCharge,
        univalence: this.form.isNeedCharge ? Number(this.form.univalence ):0,
        totalAmount: this.form.isNeedCharge ? this.totalCost : 0,
        caseNode: this.isSelectTaskInfo.businessTypeName == '代理案件' ? this.form.caseNode:null,  // 案件节点
        isClosed: this.isSelectTaskInfo.businessTypeName == '代理案件' ? this.form.isClosed:null,  // 是否结案
        closedResult: this.form.isClosed ? this.form.closedResult:null,  // 结案结果
        closedNode: this.form.isClosed ? this.form.closedNode:null,  // 结案节点
        closedContent: this.form.isClosed ? this.form.closedContent:null,  // 结案内容
        hearCaseOrganization: this.isSelectTaskInfo.businessTypeName == '代理案件' ? this.form.hearCaseOrganization:null,  // 审理案件机构
      };
      if (this.info.type == 'edit') {
        params.id = this.form.id;
      }
      // console.log(params);return
      let res3 = null;
      if (this.info.type == 'add') {
        res3 = await addServiceRecord(params)
      } else {
        res3 = await editServiceRecord(params)
      }
      if (res3.code == 0) {
        // this.$parent.getServicerecordListInfo();
        this.cancel();
      }
    },
    // 编辑任务
    // async editSubmit(params) {
    //   params.id = this.info.info.id;
    //   let res4 = await editAssignment(params);
    //   if (res4.code == 0) {
    //     this.$parent.getTaskListInfo();
    //     this.cancel();
    //   }
    // },
    // 取消
    cancel() {
      this.$parent.getServicerecordListInfo();
      this.$parent.showTable = true;
    },
    // 添加任务
    addTask() {
      if (!this.form.customerId) return;
      let name = '';
      for (let i = 0; i < this.clientList.length; i++) {
        let item = this.clientList[i];
        if (item.id == this.form.customerId) {
          name = item.name;
          break
        }
      }
      this.taskInfo = {
        id: this.form.customerId,
        name: name,
      }
      this.showTaskDialog = true;
    },
    // 新增客户
    addClient() {
      this.showClientDialog = true;
    },
  },
  beforeDestroy() {
    clearInterval(this.flag);
  },
};
</script>

<style lang="less" scoped>
.add-serve {
  display: flex;
  flex-direction: column;
  // height: 70vh;
  overflow-y: auto;
  .all-crumbs {
    margin-bottom: 20px !important;
  }
  /deep/ .row-box {
    width: 50%;
    display: flex;
    align-items: center;
    margin-bottom: 14px;
    .label {
      min-width: 72px;
      font-size: 14px;
      color: #909399;
      margin-right: 18px;
      white-space: nowrap;
      span {
        color: red;
      }
    }
    .el-input {
      flex: 1;
      height: 40px;
      font-size: 14px;
      background: #fff;
      border-radius: 6px;
      box-sizing: border-box;
      input {
        height: 100%;
        width: 100%;
        border-radius: 6px;
      }
    }
    .ipt2 {
      display: flex;
      align-items: center;
      flex: 1;
      height: 40px;
      border: 1px solid #d7dbda;
      border-radius: 6px;
      padding: 0 30px 0 15px;
      .el-input {
        flex: none;
        width: 60px;
        height: 70%;
        .el-input__inner {
          width: 100%;
          height: 100%;
          border: none;
          font-size: 14px;
          color: #333;
          border-bottom: 1px solid #d7dbda;
          border-radius: 0;
          padding-right: 0;
        }
      }
      span {
        font-size: 14px;
        color: #333;
      }
      p {
        font-size: 14px;
        color: #909399;
      }
    }
    .ipt3 {
      position: relative;
      flex: none;
      width: 40%;
      input {
        height: 100%;
        width: 100%;
        padding: 0 90px 0 40px;
      }
    }
    .ipt3::before {
      content: "¥";
      position: absolute;
      top: 0;
      left: 20px;
      color: #909399;
      line-height: 40px;
    }
    .ipt3::after {
      content: "元/小时";
      position: absolute;
      top: 0;
      right: 20px;
      color: #909399;
      line-height: 40px;
    }
    .el-textarea {
      .el-textarea__inner {
        font-size: 14px;
        border-radius: 6px;
        line-height: initial;
      }
    }
    .el-select {
      flex: 1;
      .el-input {
        width: 100%;
        .el-input__inner {
          width: 100%;
        }
      }
    }
    .el-radio-group {
      .el-radio {
        margin-bottom: 0;
        .el-radio__label {
          font-size: 14px;
        }
      }
      .is-checked {
        .is-checked {
          .el-radio__inner {
            border-color: #6474c7;
            background-color: #6474c7;
          }
        }
        .el-radio__label {
          color: #6474c7;
        }
      }
    }
  }
  .row-box2 {
    position: relative;
    .btn-p {
      position: absolute;
      top: 50%;
      right: -120px;
      color: #76b0eb;
      transform: translate(0, -50%);
      cursor: pointer;
    }
    .btn-p2 {
      color: #ccc;
      cursor:not-allowed;
    }
  }
  .label-cost {
          margin-left: 50px;
          font-size: 14px;
          color: #333;
        }
  .btns-box {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    button {
      width: 140px;
      height: 40px;
      font-size: 16px;
      font-weight: 600;
      color: #bbb;
      border-radius: 25px;
      background: #f1f2f1;
      border: none;
      margin-left: 20px;
      cursor: pointer;
    }
    .btn2 {
      color: #fff;
      background: linear-gradient(90deg, #6878c8 0%, #79baf2 100%);
      border: none;
    }
  }
}
</style>
